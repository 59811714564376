button {
    cursor: pointer;
    margin-top: 15%;
    padding: 2% 4%;
    font-size: 1rem;
    border: none;
    border-radius: 20px;
    box-shadow: 1px 1px 10px #ffffff;
    background-color: rgba(201, 107, 148, 0.5);
    color: #ffffff;
}