.container-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.burguer-menu {
    position: relative;
    top: 2%;
    left: 40%;
}

@media (max-width: 480px) {
    .container-content {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .container-content {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .container-content {
        width: 100%;
        height: 80%;
    }

    .container-content .burguer-menu {
        display: none;
    }
}

@media (min-width: 1025px) {
    .container-content {
        width: 80%;
        height: 100%;
    }

    .container-content .burguer-menu {
        display: none;
    }
}