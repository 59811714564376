.container-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.container-about img {
    width: 30px;
    height: 30px;
}

.container-about h1 {
    color: #ffffff;
    text-shadow: 8px 4px 10px #ac8395;
}

.section-title {
    width: 80%;
    margin-bottom: 2%;
    text-align: center;
    font-weight: 500;
    color: #a33159;
    background-color: rgba(255, 255, 255, 0.8);
}

.about-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    height: 85%;
    border-radius: 5%;
    margin-top: 2%;
    background-color: rgba(255, 255, 255, 0.4);
}

.softskill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25%;

}

.about-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: auto;
    width: 100%;
}

.about-section span {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1%;
    font-size: 0.8rem;
    color: #a33159;
}

.hardskill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 75%;

}

.container-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.container-icon {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.container-icon span {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1%;
    font-size: 0.8rem;
    color: #a33159;
}

@media (max-width: 480px) {
    .container-about h1 {
        font-size: 1rem;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .container-about {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .about-content {
        width: 70%;
        height: 90%;
    }
}

@media (min-width: 1025px) {
    .about-content {
        width: 50%;
        height: 80%;
    }

    .container-about img {
        width: 40px;
        height: 40px;
    }
}