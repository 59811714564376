* {
    font-family: 'Space Mono', monospace;
}

.personal-info img {
    border: 2px dashed #ffffff;
}

.container-menu ul {
    list-style: none;
}

.container-menu ul li {
    cursor: pointer;
}