.container-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20%;
}

.container-menu ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    text-align: center;
}

.container-menu ul li {
    margin-top: 2%;
    padding: 2%;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 10px;
    color: #ffffff;
    box-shadow: 1px 1px 10px #ffffff;
    background-color: rgba(172, 131, 149, 0.5);
}


@media (max-width: 480px) {
    .container-menu {
        display: none;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .container-menu {
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .container-menu {
        display: none;
    }
}