.container-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-image: url('../../assets/background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 60%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.286);
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-top: 2%;
}

.modal-header img {
    width: 15%;
}

.modal-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.modal-options li {
    font-size: 1rem;
    list-style: none;
    text-align: center;
    margin-bottom: 10%;
    width: 80%;
    padding: 2% 0;
    border-radius: 20px;
    color: #ffffff;
    box-shadow: 1px 1px 10px #ffffff;
    background-color: rgba(201, 107, 148, 0.5);
}