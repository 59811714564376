.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.main-content h1 {
    font-style: italic;
    text-align: center;
    color: #ffffff;
    text-shadow: 2px 2px 2px #8f7287;
}

a {
    text-decoration: none;
    color: #ffffff;
}

@media (max-width: 480px) {
    .main-content h1 {
        font-size: 1.5rem;
    }
}

@media (min-width: 800px) {
    .main-content h1 {
        font-size: 2.5rem;
    }
}