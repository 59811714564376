.container-app {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-image: url('./assets/background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

@media (max-width: 480px) {
    .container-app {
        flex-direction: column;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .container-app {
        flex-direction: column;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .container-app {
        flex-direction: column;
        align-items: flex-start;
    }
}


@media (min-width: 1025px) {
    .container-app {
        align-items: flex-start;
    }
}