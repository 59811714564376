.container-contact {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    height: 90%;
    color: #a33159;
    font-weight: 500;
}

.contact-text {
    text-align: center;
}

.or {
    font-size: 3.5rem;
    font-weight: 100;
    text-shadow: 1px 1px 1px #a33159;
    color: #ffffff;
}

.social-media,
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 40%;
    height: 70%;
    border-radius: 5%;
    background-color: rgba(255, 255, 255, 0.5);
}

.form-container form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 80%;
    height: 80%;
}

.form-container label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    font-size: 0.9em;
}

.form-container input {
    width: 100%;
    height: 30%;
    border: none;
    color: #8f7287;
}

.form-container button {
    width: 100%;
}

.message-area {
    margin-top: 10%;
}

.message-area input {
    width: 100%;
    height: 100%;
}

input:focus {
    outline: none;
}

@media (max-width: 480px) {
    .container-contact {
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .social-media {
        width: 90%;
        height: 30%;
    }

    .form-container {
        width: 90%;
        height: 60%;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .container-contact {
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .social-media {
        width: 90%;
        height: 30%;
    }

    .form-container {
        width: 90%;
        height: 60%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .container-contact {
        flex-direction: column;
    }

    .social-media {
        width: 60%;
        height: 30%;
    }

    .form-container {
        width: 60%;
        height: 60%;
    }
}

@media (min-width: 1025px) {}